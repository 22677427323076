.breadcrumb_content {
  display: block;
  padding: 15px;
  justify-content: space-between;
  @apply md:flex
}
.breadcrumb_nav {
  @apply mb-3
}
.breadcrumb_hours {
  @apply pl-6 md:pl-0
}
.breadcrumb_money {

}