.filter_text{
  text-align: left;
  margin-left: 10px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 20px;
}
.select_departaments,
.select_provinces,
.select_districts{
  text-align: center;
  margin-top: 10px;
}
.select_departaments select,
.select_provinces select,
.select_districts select{
  width: 80%;
}
.select{
  padding: 6px 12px;
  height: 56px;
  border-radius: 5px;
  -webkit-appearance: none;
  background: #FAFAFA;
  background: url('/img/arrow-dow.svg') no-repeat 94% 50%;
  background-color: #fafafa;
  box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
  -webkit-box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
  -moz-box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
  
}
.buscar{
  display: block;
  margin: auto;
  margin-top: 20px;
  background: #009688;
  border-radius: 18px;
  color: white;
  height: 36px;
  width: 140px;
  box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
  -webkit-box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
  -moz-box-shadow: -1px 2px 5px -2px rgb(0 0 0 / 75%);
}

@media (min-width: 970px) {
  .select_departaments,
  .select_provinces,
  .select_districts,
  .buscar{
    margin-top: unset;
  }
  .select_departaments select{
    width: 259px;
  }
  .select_provinces select{
    width: 150px;
  }
  .select_districts select{
    width: 150px;
  }
  .buscar{
    margin-bottom: unset;
    width: 140px;
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
  }
  .filter_container{
    display: flex;
    margin-top: 10px;
    padding: 15px 15px;
    justify-content: space-between;
    height: 86px;
  }
  .filter_text{
    height: fit-content;
    text-align: left;
    max-width: 130px;
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
  }
  .buscar{
    margin: unset;
  }
}