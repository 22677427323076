.bg_footer{
  @apply pt-[88px] pb-[124px] h-1/2 w-full flex md:flex-row flex-col justify-around items-start md:p-20;
  background: #067B87;
}

.subscription{
  width: 100%;
  background: #884485;
  
}
.container{
  display: flex;
  max-width: 960px;
  height: 75px;
  margin: auto;
  justify-content: space-between;
}
.subscription_content{
  display: flex;
}
.icon{
  margin-top: 1.3rem;
  margin-left: 10px;
}
.subscription_content_title{
  @apply my-auto;
  width: 200px;
  font-size: 0.8rem;
  margin-left: 10px;
  color: white;
}
.subscription_action{
  display: flex;
}

.subscription_action_button button{
  margin-top: 1.3rem;
  font-size: 0.8rem;
  width: 100px;
  height: 25px;
  border-radius: 36px;
  background: #009688;
  color: white;
  box-shadow: 1px 4px 12px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px 4px 12px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 4px 12px -5px rgba(0,0,0,0.75);
}
.subscription_action_close{
  margin-top: 1.5rem;
  margin-right: 10px;
  margin-left: 10px;
}
.subscription_action_close img{
  width: 23px;
  height: 23px;
}

.footer{
  width: 100%;
  background: #067B87;
}
.footer_container{
  @apply pt-[58px] pb-[94px];
  text-align: center;
  display: grid;
  gap: 15px;
}
.footer_nav_list{
  color: white;
}
.footer_nav_list p{
  font-weight: bold;
}
.footer_nav_list ul{
  
}
.footer_nav_list ul li img{
  display: inline;
}
.footer_nav_list ul li{
  margin-top: 30px;
}
.contact ul li{
  font-size: 0.7rem;
  margin-top: 25px;
}

.seccion ul li {
  @apply text-white;
  margin-top: 35px !important;
}

@media (min-width: 970px) {
  .footer_container{
    display: flex;
    max-width: 960px;
    margin: auto;
    justify-content: space-between;
    text-align: left;
  }
  .icon{
    margin-top: 1.3rem;
    margin-left: 30px;
  }
  .subscription_content_title{
    @apply my-auto;
    width: auto;
    margin-left: 35px;
    color: white;
  }
  .subscription_action_close img{
    width: 23px;
    height: 23px;
  }
  .subscription_action_button button{
    width: 140px;
    height: 36px;
    margin-top: 1.3rem;
    margin-right: 35px;
  }
  .subscription_action_close{
    margin-right: 30px;
  }

  .seccion ul li {
    @apply text-white;
    margin-top: 25px !important;
  }
}
