.lista{
    @apply flex py-5 md:p-0 font-bold text-lg cursor-pointer items-center;
}

.bghoverr:hover {
    background-color: black; /* Puedes ajustar el color según tus necesidades */
}

.shadow_button{
    box-shadow: 0px 0px 4px 0.1px rgba(109, 107, 107, 0.75);
    -webkit-box-shadow: 0px 0px 4px 0.1px rgba(177, 177, 177, 0.5);
    -moz-box-shadow: 0px 0px 4px 0.1px rgba(109, 107, 107, 0.75);
}